<template>
  <div>
    <!-- <el-button @click="tst">tst</el-button> -->
    <survey :survey="survey" />
  </div>
</template>

<script>
import * as Survey from "survey-vue";
import "survey-vue/modern.css";
import axios from 'axios'
import { hostus } from "../utils/func.js";

//const hostus = (process.env.NODE_ENV == "development" ? 'http://localhost:3000' : '')

Survey.StylesManager.applyTheme("modern"); //.applyTheme("survey")

export default {
  name: "Surveycom",
  props: {
    /* jsonin: Object, */ id: Number, userid: Number, elres: Object, isClosed: Boolean
  },
  data() {
    const survey = new Survey.Model( this.json )
    return {
      survey: survey,
      result: {res:''}, 
      json: {}
    };
  },
  watch: {
    resultok: function (val) { this.$emit('complete', val) },
    id: function () { this.set_survey() },
    elres: function () { this.set_survey() }
  },
  computed: {
    resultok: function() {return this.result.res},
    user_dis: function() { //console.log('this.userid=',this.userid)
      if (this.userid == this.$store.getters['user_da'].id) return this.$store.getters['user_da'].dis
      else return 0 // т.к. в списке "Голосовать за" нет тех, кто не участвует в голосовании
    }
  },
  async created() { 
    this.set_survey()
  },
  methods: {
    //tst () { console.log('this.$data=',this.$data, this.result1)}
    async set_survey () { //console.log('this.user_dis',this.user_dis)
      await axios.post(`${hostus}/mysqlus/sel_elect`, {id: this.id})
      .then(response => { 
        const { data } = response
        if (data.errmsg) { this.$alert(data.errmsg, 'Ошибка', {})}
        else { 
        if (this.$store.getters['debug']) console.log('data_survey=',data)
        this.json = Object.assign( {"showQuestionNumbers": "off"}, JSON.parse(data[0].content) ) // всегда data[0], т.к. возвр. только одна запись
        }
      })
      this.survey = new Survey.Model( this.json )
      this.survey.data = this.elres
      //console.log('this.survey.data=',this.survey.data,this.isClosed)
      if (this.isClosed || this.user_dis) this.survey.mode = "display"
      let result = this.result
      this.survey.onComplete.add(function (sender) {
        sender.clear(false);
        sender.mode = "display";
        result.res = sender.data //JSON.stringify(sender.data, null, 3);
      });
    }
  }
};
</script>

<style scoped>
</style>

